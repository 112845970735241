export default {
  data() {
    return {
      parks: [],
      selectedPark: null
    };
  },
  async mounted() {
    await this.loadParks();
    //console.log('---> localStorage.railsWeekCalendarParkFilter: ' + localStorage.railsWeekCalendarParkFilter);
    if (localStorage.railsWeekCalendarParkFilter)
      this.selectedPark = JSON.parse(localStorage.railsWeekCalendarParkFilter);
    else
      this.selectedPark = this._.find(this.parks, (p) => p.parcoDefault);
    //console.log('---> selectedPark: ' + JSON.stringify(this.selectedPark));
  },
  watch: {
    selectedPark() {
      console.log('-------> selectedPark: ' + JSON.stringify(this.selectedPark));
      if (!!this.selectedPark)
        localStorage.railsWeekCalendarParkFilter = JSON.stringify(this.selectedPark);
    } 
  },
  methods: {
    async loadParks() {
      try {
        const res = await this.$api.railData.getParchiBinari();
        this.parks = res.data;
      } catch(e) {
        console.log(e);
      }
    },
  },
  computed: {
    parkName() {
      return this.selectedPark && this.selectedPark.codice ? this.selectedPark.codice : '';
    },
  },
};
