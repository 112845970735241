<template>
  <div class="rails-caldendar">
    <v-container class="rails-calendar-cntr">
      <v-row>
        <v-col>
          <v-alert dense text>
            {{ $utilities.dates.formatDate(startDate) }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" v-if="planningEnabled"> 
            <rails-panel
              :title="'Da pianificare'" 
              :tracksOccupancies="unplannedTracksOccupancies"
              :parkCode="parkName"
              :editMode="true"
              v-model="startDate"   
              :shifts="shifts"    
              isPlanning    
            />   
        </v-col>
        <v-col 
          v-for="rail in rails" 
          :key="rail.id"  
        >
          <rails-panel
            :title="rail.descrizione" 
            :tracksOccupancies="plannedTracksOccupancies[rail.id]"
            v-model="startDate"
            :parkCode="parkName"
            :editMode="editMode"    
            :shifts="shifts"  
            :planningEnabled="planningEnabled"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
  </template>
  
  <script>
  import moment from 'moment';
  import railsPanel from '../misc/RailsPanel';
  
  export default {
    name: "railsCalendar",
    components: {
      railsPanel,
    },
    props: {
      parkName: {
        type: String,
        required: true
      },
      planningEnabled: {
        type: Boolean,
        default: false
      },
      editMode: {
        type: Boolean,
        default: false
      },
      value: {
          type: String,
          default: () => moment().format('YYYY-MM-DD')
      }
    },
    data() {
      return {
        rails: [],
        railGroups: [],
        plannedTracksOccupancies: [],
        unplannedTracksOccupancies: [],
        shifts: [],
        startDate: null,
        shiftsEnabled: process.env.VUE_APP_SHIFTS_ENABLED || "false",
        calendarStartTime: process.env.VUE_APP_CALENDAR_START_TIME || 0,
        convoyDate: null
      };
    },
    created() {
      this.$eventBus.$on('convoySaved', async () => {
        await this.setConvoys(this.startDate);
        this.rails = Object.assign([], this.rails);
      });
      this.$eventBus.$on('refreshCalendar', () => {     
        this.fetchData();   
      });
    },
    async mounted(){
      //--- eliminato ---await this.fetchData();   
      if (this.value) {
        this.startDate = JSON.parse(JSON.stringify(this.value));
      } 
      await this.calculateShifts();       
    },
    computed: {
    },
    methods: {
      async calculateShifts() {
        if (this.shiftsEnabled==="true") {
          this.shifts = new Array();
          // console.log("+++++++++++++++++++++++ startDate", this.startDate);
          var turni = await this.$api.railData.getTurniByDate(this.startDate);
          // console.log("turni", turni);
          turni.forEach(turno => {
            var shift = new Object();
            if (new Number(turno.inizio.replace(':', ''))>new Number(turno.fine.replace(':', ''))) {
              var startDate1DayAfter = moment(this.startDate).add(1, 'days').format('YYYY-MM-DD');
              var startDate1DayBefore = moment(this.startDate).subtract(1, 'days').format('YYYY-MM-DD');  
              shift.end = startDate1DayAfter + " " + turno.fine;
  
              var shiftDayBefore = new Object();
              shiftDayBefore.start = startDate1DayBefore + " " + turno.inizio; 
              shiftDayBefore.end = this.startDate + " " + turno.fine;
              // shiftDayBefore.name = turno.nome;
              this.shifts.push(shiftDayBefore);
            } else {
              shift.end = this.startDate + " " + turno.fine;
            }      
            shift.start = this.startDate + " " + turno.inizio;          
            shift.name = turno.nome;
            this.shifts.push(shift);
          });
          // console.log("+++++++++++++++++++++++ shifts", this.shifts);
        }
      },
      async fetchData() {
        try {
          await this.setConvoys(this.startDate);
          await this.setRails();
        } catch(e) {
          console.log(e);
        }
      },
      async setRails(){
        if(this.parkName){
          // console.log("time before query getRailwayData: ",new Date());
          this.rails = await this.$api.railData.getRailwayData(this.parkName);
          // console.log("time after query getRailwayData: ",new Date());
        }      
      },
      async setConvoys(currDate){
        this.convoyDate=currDate;
        try{
  
          const tsDateFrom = this.$utilities.dates.getTsFromDateFormat(moment(currDate).add(this.calendarStartTime, 'hour'));
          const tsDateTo = this.$utilities.dates.getTsFromDateFormat(moment(currDate).add(1, 'days').add(this.calendarStartTime, 'hour'));
  
          this.plannedTracksOccupancies = {};
          this.unplannedTracksOccupancies = [];
          const tracksOccupancies = await this.$api.trainData.getTracksOccupancies(tsDateFrom, tsDateTo);
          // console.log("+++++++++++++++++ tracksOccupancies", currDate, tracksOccupancies.length, tracksOccupancies);
          this._.forEach(tracksOccupancies, occ => {
            // console.log("+++++++++++++++++ binarioId", occ.binarioId, occ.convoglio.nome);
            if(occ.binarioId) {
              if(!this.plannedTracksOccupancies[occ.binarioId]){
                this.plannedTracksOccupancies[occ.binarioId] = [];        
              } 
              this.plannedTracksOccupancies[occ.binarioId].push(occ);                                                 
            } else if (occ.convoglio.stato!="USCITOTERMINAL" && occ.convoglio.stato!="CANCELLATO") {
                this.unplannedTracksOccupancies.push(occ);
            }
          });
          // console.log("+++++++++++++++++ plannedTracksOccupancies", this.plannedTracksOccupancies);
          // console.log("+++++++++++++++++ unplannedTracksOccupancies", this.unplannedTracksOccupancies);
          // console.log("++++++++++++++++++++++++++++++");
        } catch(e) {
          console.log(e);
        }
      } 
    },
    watch: {
      parkName: async function () {
        await this.fetchData();
      },
      value: async function() {
        if (this.value) {
          this.startDate = JSON.parse(JSON.stringify(this.value));
        }
        await this.calculateShifts();
        this.$emit('input', this.startDate);
        await this.setConvoys(this.startDate);   
        this.rails = Object.assign([], this.rails);  
      }
    }
  };
  </script>
  
  <style scoped>
  .rails-caldendar{
    overflow: auto;
  }
  .rails-calendar-cntr{
    /* width: 1500px !important; */
    max-width: 3000px !important;
  }
  </style>
  