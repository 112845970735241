<template>
    <div class="legendClass">
        <div
            v-for="detail in details"
            :key="detail.label" 
            class="legend-detail"
        >
            <div
                v-if="detail.color && detail.label"
                :style="{
                    'background': detail.color, 
                    'width': '12px', 
                    'height': '10px', 
                    'margin-right': '5px', 
                    'margin-top': '6px',
                    'border-radius': '2px', 
                    'border-width': detail.border,
                    'border-color': '#000000',
                    'border-style': detail.border?'solid':null
                    }"
            />
            <div v-else-if="detail.text" class="text-center text-caption font-weight-black" >
                {{detail.text}}
            </div>
            
            <span class="text-uppercase text-caption">{{ detail.label }}</span>
        </div>
    </div> 
</template>

<script>
export default {
    name: "legendComponent",
    data() {
        return {
            // details: [
            //     { label: 'pirolo', color: '#ef6262' },
            //     { label: 'pastura', color: '#cccccc' }
            // ]
        };
    },
    props: {
        details: {
            type: Object,
            required: true
        },
    },
    watch: {
    },
    methods: {
    }
}
</script>

<style scoped>
.legend-detail,
.legend-detail > div
{
    float: left;
    padding-left: 5px;
}
.legend-detail > span
{
    font-size: 0.8em;
}
</style>