import moment from "moment";

export default {
    data() {
        return {
            rules: {
                required: value => !!value || 'Campo obbligatorio.',
                numeric: value => (!value || !isNaN(value)) || 'Il campo deve essere numerico.',
            },
            validationRules: {
                required: value => !!value || 'Campo obbligatorio.',
                requiredOrZero: value => value===0 || !!value || 'Campo obbligatorio.',
                numeric: value => (!value || !isNaN(value)) || 'Il campo deve essere numerico.',
                greaterThanOrEqualToZero: v => (!isNaN(v) && +v >= 0) || 'valore numerico non valido',
            }
        };
    },

    filters: {
        formatShortDate: function(value) {
            return value ? moment(parseInt(value)).format("DD/MM/YYYY") : '';
        },
        formatShortDateFormat: function(value) {
            return value ? moment(value).format("DD/MM/YYYY") : '';
        },
        formatDate: function(value) {
            return value ? moment(parseInt(value)).format("DD/MM/YYYY HH:mm") : '';
        },
        formatDateTimeNoYear: function(value) {
            return value ? moment(parseInt(value)).format("DD/MM HH:mm") : '';
        },
        formatDateTimeNoYearFromDate: function(date) {
            return date ? moment(date).format("DD/MM HH:mm") : '';
        },
        formatTime: function(value) {
            return value ? moment(parseInt(value)).format("HH:mm") : '';
        },
        formatTimeFromDate: function(date) {
            return date ? moment(date).format('HH:mm') : '';
        },
        formatDateFromDate: function(date) {
            return date ? moment(date).format("DD/MM/YYYY") : '';
        },
        formatDateTimeFromDate: function(date) {
            return date ? moment(date).format("DD/MM/YYYY HH:mm") : '';
        },
        formatDateTimeSecsFromDate: function(date) {
            return date ? moment(date).format("DD/MM/YYYY HH:mm:ss") : '';
        },
        formatBoolean: function(value) {
            return value ? 'SI' : 'NO';
        },
        label: function(value) {
            if (value) return value.label;
        },
        codice: function(value) {
            if (value) return value.codice;
        },
        descrizione: function(value) {
            if (value) return value.descrizione;
        },
        listSize: function(list) {
            if (list && list.length) return list.length;
            return 0;
        },
        numberRounded2WithComma: function(number) {
            return number.toFixed(2).replace(".", ",");
        },

        humanize: function(camelCaseString) {
            const words = camelCaseString.match(/[A-Za-z][a-z]*/g) || [];
            const s = words.join(" ");
            return s.charAt(0).toUpperCase() + s.substring(1);
        },
    },
    methods: {
        formatPeriodicita: function(periodicita) {
            const map = {
                "1": "L",
                "2": "M",
                "3": "M",
                "4": "G",
                "5": "V",
                "6": "S",
                "7": "D"
            };
            let htmlStr = "";
            Object.keys(map).forEach(key => {
                if (typeof periodicita === 'string' && periodicita.includes(key)) {
                    htmlStr += `<span style="font-weight:bold;">${map[key]}</span>&nbsp;`
                } else {
                    htmlStr += `<span style="color:#cccccc;">${map[key]}</span>&nbsp;`
                }
            });
            return htmlStr;
        },
    },

};