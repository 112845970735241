<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" class="text-center">
        <v-row align="center">
          <v-col cols="6" class="text-center">
            <div class="rail-week-cal-cmd">
              <div>
                <v-btn outlined fab left color="primary" @click="incrStartDate(-1)" elevation="3">
                  <v-icon x-large>mdi-chevron-left</v-icon>
                </v-btn>
              </div>
              <div>
                <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field :value="startDateFormat" persistent-hint prepend-icon="event" v-on="on" />
                  </template>
                  <v-date-picker v-model="startDateIsoFormat" no-title @input="menu1 = false" @change="startDateChanging" />
                </v-menu>
              </div>
              <div>
                <v-btn outlined fab right color="primary" @click="incrStartDate(1)" elevation="3">
                  <v-icon x-large>mdi-chevron-right</v-icon>
                </v-btn>
              </div>             
            </div>
          </v-col>
          <v-spacer />              
          <v-switch
            v-model="planningEnabled"
            inset
            prepend-icon="mdi-calendar-edit"
            :title="planningEnabled?tLabel('DISATTIVA PIANIFICAZIONE'):tLabel('ATTIVA PIANIFICAZIONE')"
          ></v-switch>
          <v-col cols="6" md="2" sm="8">
            <v-combobox v-model="selectedPark" :items="parks" :label="tLabel('Parco selezionato')" item-text="codice" />
          </v-col>
          <v-btn small outlined fab right color="primary" @click="refresh" elevation="3" :title="tLabel('Aggiorna')" style="margin-left: 5px; margin-right: 5px;">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn small outlined fab right color="primary" @click="resetFilters" elevation="3" :title="tLabel('Azzera filtri')" style="margin-left: 5px; margin-right: 5px;">
            <v-icon>mdi-broom</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="py-2">
      <v-col cols="12">
        <calLegend :details="$config.rails.convoyStates"/>
      </v-col>
    </v-row>    
    <railsCalendar v-for="currDateObj in currDates" :key="currDateObj.idx" :planningEnabled="planningEnabled" :editMode="true" v-model="currDateObj.value" :parkName="parkName" />
  </v-container>
</template>

<script>
import moment from "moment";
import multiParkManagementMixins from "../../mixins/MultiParkManagementMixins";
import railsCalendar from "../../components/rails/RailsCalendar";
import calLegend from "../../components/misc/Legend";

export default {
  name: "railsWeekCalendar",
  components: {
    railsCalendar,
    calLegend,
  },
  data() {
    return {
      startDate: moment(),
      startDateFormat: "",
      startDateIsoFormat: "",
      currDates: null,
      planningEnabled: false,
      menu1: null,
    };
  },
  mixins: [multiParkManagementMixins],
  created() {
    if (localStorage.railsWeekCalendarStartDateFilter)
      this.startDate = moment(localStorage.railsWeekCalendarStartDateFilter);
    else
      this.startDate = moment();
    this.setDates();
  },
  watch: {
    startDate() {
      if (this.startDate && this.startDate.format("YYYY-MM-DD") != moment().format("YYYY-MM-DD"))
        localStorage.railsWeekCalendarStartDateFilter = this.startDate.format("YYYY-MM-DD");
      else
        localStorage.removeItem("railsWeekCalendarStartDateFilter");
      console.log('railsWeekCalendarStartDateFilter ----->', localStorage.railsWeekCalendarStartDateFilter);
    }
  },
  computed: {},
  methods: {
    setDates() {
      this.startDateFormat = this.startDate.format("DD/MM/YYYY");
      this.startDateIsoFormat = this.startDate.format("YYYY-MM-DD");
      const currDates = [];
      for (let i = 0; i < 7; i++) {
        currDates.push({
          idx: i,
          value: moment(this.startDateIsoFormat).add(i, "days").format("YYYY-MM-DD"),
        });
      }
      this.currDates = currDates;
    },
    startDateChanging() {
      this.startDate = moment(this.startDateIsoFormat);
      this.$refs.menu1 = false;
      this.setDates();
    },
    incrStartDate(days) {
      this.startDate = moment(this.startDateIsoFormat).add(days, "days");
      this.setDates();
    },
    refresh() {
      this.$eventBus.$emit('refreshCalendar');
    },
    resetFilters() {
      this.startDate = moment();
      this.setDates();
      this.selectedPark = this.parks.find((p) => p.parcoDefault);
      //this.$eventBus.$emit('refreshCalendar');
    }
  },
};
</script>
<style scoped>
.rail-week-cal-cmd div {
  float: left;
  padding: 5px;
}
</style>
